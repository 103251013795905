.border-radius
  border-radius: 10px
.border-small-radius
  border-radius: 4px
.pointer
  cursor: pointer
.vertical-line
  width: 2px
  height: 100%
  background-color: #707070
a
  color: #606060 !important
  text-decoration: none!important
  cursor: pointer
a:hover
  color: #0038b8!important
.btn:hover
  border-color: transparent!important
.rtl
  .margin
    margin-right: 10px
  .medium-margin
    margin-right: 20px
  .big-margin
    margin-right: 30px
  .text-justify
    text-align: justify !important
    text-align-last: right

.ltr
  .margin
    margin-left: 10px
  .big-margin
    margin-left: 30px
  .medium-margin
    margin-left: 20px
  .text-justify
    text-align: justify !important
    text-align-last: left
.swiper-pagination
  bottom: 0px !important
.hide-li
  list-style: none!important

@media (max-width: 767.98px)
  .border-radius-sm-none
    border-radius: 0px
.shadow-border
  border: 1px solid rgba(138,143,155,.09)
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal
  bottom: 0px!important
i
  font-style: normal!important
