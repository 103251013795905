@font-face {
  font-family: 'fontello';
  src: url('fontello.eot?94195810');
  src: url('fontello.eot?94195810#iefix') format('embedded-opentype'), url('fontello.woff2?94195810') format('woff2'),
    url('fontello.woff?94195810') format('woff'), url('fontello.ttf?94195810') format('truetype'),
    url('fontello.svg?94195810#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?94195810#fontello') format('svg');
  }
}
*/
[class^='icon2-']:before,
[class*=' icon2-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-fish:before {
  content: '\e810';
} /* '' */
.icon-nuts:before {
  content: '\e802';
} /* '' */
.icon-dairy:before {
  content: '\e803';
} /* '' */
.icon-car:before {
  content: '\e804';
} /* '' */
.icon-electricity:before {
  content: '\e805';
} /* '' */
.icon-appliance:before {
  content: '\e806';
} /* '' */
.icon-fruit:before {
  content: '\e807';
} /* '' */
.icon-sweets:before {
  content: '\e808';
} /* '' */
.icon-mine:before {
  content: '\e809';
} /* '' */
.icon-flower:before {
  content: '\e80a';
} /* '' */
.icon-alterant:before {
  content: '\e80b';
} /* '' */
.icon-textile:before {
  content: '\e80c';
} /* '' */
.icon-mineral:before {
  content: '\e80d';
} /* '' */
.icon-petrochemical:before {
  content: '\e80e';
} /* '' */
.icon-chemical:before {
  content: '\e80f';
} /* '' */
