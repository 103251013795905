$primary-color: #0038b8
.light-gray
  color: #CFD6D7!important
.bg-light-gray
  background-color: #CFD6D7!important
.white
  color: white!important
.bg-white
  background-color: white!important
.blue
  color: #0038b8!important
.bg-blue
  background-color: #0038b8!important
.bg-light-blue
  background-color: #4e82ae!important
.orange
  color: #ff6f00!important
.bg-orange
  background-color: #ff6f00!important
.dark-gray
  color: darkgray
.medium-gray
  color: #6E6E6E
.red
  color: red!important
.bg-light-orange
  background-color: #FFF2F1!important
.dark-orange
  color: #FC8149!important
.persian-blue
  background-color: #1c39bb !important
.medium-persian-blue
  background-color: #0067a5 !important
