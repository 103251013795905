@font-face
  font-family: 'Fontello'
  src: url("../assets/fonts/fontello/fontello.ttf") format('truetype')
  font-display: swap
@font-face
  font-family: 'IranSans'
  src: url("../assets/fonts/persian/IRANSansXFaNum-Regular.otf") format('truetype')
  font-display: swap
@font-face
  font-family: 'SegoeUI'
  font-style: normal
  font-weight: normal
  src: local('Segoe UI Regular'), url('../assets/fonts/english/Segoe UI.woff') format('woff')
  font-display: swap


.rtl
  *
    font-family: "IranSans", serif !important
.ltr
  *
    font-family: "SegoeUI" , serif !important
.fontello
  color: #0038b8
  font-family: "Fontello", serif !important
  font-weight: 700
  font-size: 24px
  width: 42px
.bold
  font-weight: bold
.fs-12
  font-size: 12px!important
.fs-14
  font-size: 14px!important
.fs-16
  font-size: 16px!important
.fs-18
  font-size: 18px!important
.fs-20
  font-size: 20px!important
.fs-22
  font-size: 22px!important
.fs-24
  font-size: 24px!important
.font-english
  font-family: "SegoeUI" , serif !important
  text-align: justify !important
  text-align-last: left !important
