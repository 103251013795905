
/* You can add global styles to this file, and also import other style files */
body
  min-height: 100vh!important
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

/* custom scrollbar */
::-webkit-scrollbar
  width: 20px

::-webkit-scrollbar-track
  background-color: transparent

::-webkit-scrollbar-thumb
  background-color: #1a42b5
  border-radius: 20px
  border: 6px solid transparent
  background-clip: content-box

::-webkit-scrollbar-thumb:hover
  background-color: #a8bbbf
/* custom scrollbar */

/* pagination remove after next and prev
.ngx-pagination .pagination-next a:after, .ngx-pagination .pagination-next.disabled:after
  content: ""!important
  display: none!important
  margin-left: .5rem
.ngx-pagination .pagination-previous a:before, .ngx-pagination .pagination-previous.disabled:before
  content: ""!important
  display: none!important
  margin-right: .5rem

img
  max-width: 100%
.grecaptcha-badge
  display: none!important

//.swal2-container
//  direction: rtl
//.swal2-popup
//  text-align: right

@media screen and (min-width: 768px)
  .w-md-50
    width: 50%

div:where(.swal2-container) .swal2-html-container
  direction: inherit!important

.datepicker-outer-container
  position: absolute
  z-index: 10

.rtl
  .datepicker-outer-container
    right: 2px

.ltr
  .datepicker-outer-container
    left: 2px
